<template lang="">
  <div>
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :is-edit="false"
        :is-delete="false"
      >
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard, BButton, BFormSelect, BFormGroup, BRow,BCol, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormSelect,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    FormVSelect,
    BInputGroup,
    BInputGroupAppend
  },
  data() {
    return {
      dataurl:"/project",
      baseroute:"project",
      title:"Daftar Project",
      fields: [
        { key: 'area.name', label: 'Area', sortable: true},
        { key: 'code', label: 'Kode', sortable: true},
        { key: 'name', label: 'Project', sortable: true},
        { key: 'io_number', label: 'Nomor IO', sortable: true},
        { key: 'leader.name', label: 'Pimpinan'},
        { key: 'pic.name', label: 'PIC Operasional'},
      ],
    }
  },
  methods:{
    show(){
      this.$refs.basetable.fetchData()
    },
    reset(){
      this.show()
    }
  }
}
</script>
<style lang="">

</style>
